<script setup>
const analytics = useAnalytics();
    const props = defineProps({
        isShadowBoxed: {
            type: Boolean,
            default: false
        },
        isWhiteCard: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: {}
        },
        big: {
            type: Boolean,
            default: () => false   
        }
    })
    const isAdvert = computed(() => props.item?.ad_link ? true : false);
    const nuxtLinkSrc = computed(() => isAdvert ? props.item?.ad_link : `moto/artykul/${props.item?.slug}`  );
</script>
<template>
    <NuxtLink v-if="!isAdvert" :to="`${item?.slug ? `/moto/artykul/${item?.slug}` : item?.ad_link}`" 
    :class="{'shadow-blog-box-card rounded-bl-lg rounded-br-lg pb-4': isShadowBoxed,
'flex flex-col h-full': isWhiteCard
    }">
        <div :class="{'mb-3 flex-1': !isWhiteCard}" class="relative">
            <img v-if="item" :class="
                {'rounded-lg' : !isShadowBoxed && !isWhiteCard,
                'xl:h-[308px]' : big,
                'rounded-tr-lg rounded-tl-lg': isWhiteCard}" class="w-full h-[200px] object-cover" :src="item?.main_photo"/>
            <p class="absolute bottom-0 left-0 rounded-tr-lg rounded-bl-lg h-10 px-4 py-4 bg-dark-red text-white font-semibold inline-flex items-center justify-center">
                {{item?.category?.name}}
            </p>
        </div>
        <div :class="{'bg-white rounded-br-lg rounded-bl-lg pb-4 pt-3 h-full': isWhiteCard}" class="px-4 relative">
            <p class="text-xl font-normal mb-1 relative">
                <span :class="{'line-clamp-3': isWhiteCard}">{{item?.title}}</span>
                <p :class="{'text-black': isWhiteCard}" class="text-sm font-extralight">{{ item?.publication_date}}</p>
            </p>
        </div>
    </NuxtLink>
    <NuxtLink 
        v-if="isAdvert" 
        :to="nuxtLinkSrc" 
        :class="{'shadow-blog-box-card rounded-bl-lg rounded-br-lg pb-4': isShadowBoxed}"
        @click="analytics.analyticsLayer({
                event: 'select_promotion',
                creative_name: item?.title ? item?.title : '',
                type: 'Najnowsze',
                slot: 'Reklama',
                url: nuxtLinkSrc
            })"
    >
        <div :class="{'mb-3': !isWhiteCard}" class="relative">
            <img v-if="item" :class="{'rounded-lg' : !isShadowBoxed}" class="w-full h-[200px] xl:h-[308px]  object-cover" :src="item?.main_photo"/>
            <p class="absolute bottom-0 left-0 rounded-tr-lg rounded-bl-lg h-10 px-4 py-4 bg-dark-red text-white font-semibold inline-flex items-center justify-center">
                {{item?.category?.name}}
            </p>
        </div>
        <div :class="{'bg-white rounded-br-lg rounded-bl-lg pb-4 pt-3 h-full': isWhiteCard}" class="px-4">
            <p class="text-xl font-normal mb-1">
                <span :class="{'line-clamp-3': isWhiteCard}">{{item?.title}}</span>
            </p>
            <p :class="{'text-black': isWhiteCard}" class="text-sm font-extralight">{{ item?.publication_date}}</p>
        </div>
    </NuxtLink>
</template>